export const Data = {
  profile: {
    name: "NGUYEN HOANG TAM",
    ocupation: "Backend Developer",
    location: "Ho Chi Minh",
    email: "nhtam1989@gmail.com",
    telephone: "0764260026",
    image: "images/90208122_10222269974249078_3631065450546200576_n.jpg",
  },
  aboutMe: {
    label: "Summary",
    description:[
      "I'm a full-stack web developer with 4 years of experience in JavaScript (Node.js + React.js) and 3 years of experience in PHP (MVC + Laravel), i have a strong understanding and database design.",
      "Learning new languages and technologies is my passion.",
      "I work well both independently and within groups. Additionally, I can also do some mentoring tasks and code reviewing for other members"
    ]
  },
  skills: {
    technicalLabel: "Skills",
    softLabel: "Skills",
    programmingLaguages: [
      "JavaScript",
      "PHP",
      "HTML, CSS"
    ],
    databases: [
      "Mysql",
      "Postgresql ",
      "MongoDB"
    ],
    versionControl: [
      "Git (Git Shell & Github)"
    ],
    technicalSkills: [
      "Nodejs / ExpressJs",
      "Nextjs / Reactjs",
      "Angular",
      "Laravel",
      "Redis - ElasticSearch",
      "Docker",
    ],
    knowledge: [
      "Deep understanding about OOP, Design Pattern",
    ]
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "Maaato",
        name: "linkedin",
        url: "https://www.linkedin.com/in/matias-alfonso-lagos-gajardo",
        className: "bxl-linkedin-square",
      },
      {
        label: "Maaato",
        name: "github",
        url: "https://github.com/Maaato",
        className: "bxl-github",
      },
      {
        label: "Maaato",
        name: "twiiter",
        url: "https://twitter.com/I_Maaato",
        className: "bxl-twitter",
      },
    ],
  },
  experience: {
    works: [
      {
        title: "FULL-STACK DEVELOPER",
        period: "04/2020 - 06/2024",
        company: "The Parent Inc",
        projects: [
          {
            "show": true,
            "teamSize": 5,
            "title" :"webtretho.com",
            "url"   : "https://webtretho.com",
            "description":[ "This website is a community for Moms to share their child care experiences"],
            "responsiblities": [
              "Develop new features and handle errors on the website",
              "Analyze, design the database and propose solutions to the team leader",
              "Integrate with the third-party Onepay system for user payments.",
              "Optimize slow queries, such as retrieving the members list and posts list in the CMS.",
            ],
            "technologies": [
              "Backend : Nodejs, ExpressJs, Apollo GraphQl.",
              "Frontend: Nextjs, Reactjs, Redux.",
              "Cache   : Redis, ElasticSearch",
              "Database: Mysql"
            ],
          },
          {
            "show":true,
            "teamSize": 2,
            "title" :"oxii.vn",
            "url"   : "https://oxii.vn/",
            "description":[ "Car and Technology news"],
            "responsiblities": [
              "Analyze the database and migrate from SQL Server to MySQL.",
              "Convert .Net framework to Wordpress",
            ],
            "technologies": [
              "Backend : PHP, Wordpress",
              "Database: Mysql"
            ],
          },
          {
            "show": true,
            "teamSize": 4,
            "title":"awards.theasianparent.com",
            "url" : "https://awards.theasianparent.com",
            "description": ["This website allows parents to vote for products in Southeast Asia."],
            "responsiblities": [
              "Create a Voting Product API.",
              "Create an API to retrieve a list of brands",
              "Create HTML and CSS for new pages."
            ],
            "technologies": [
              "Backend : PHP, Wordpress",
              "Frontend: HTML + CSS",
              "Cache   : Redis",
              "Database: Mysql",
              "Docker"
            ],
          }
        ]
      },
      {
        title: "BACKEND DEVELOPER",
        period: "03/2019 - 03/2020",
        company: "Base Business Solution",
        teamSize: 2,
        projects: [
          {
            "show": false,
            "title" :"System Vtiger CRM ",
            "description": [
              "Customer Management and Care System for Banks"
            ],
            "responsiblities": [
              "Communicate with customers to gather clear requirements.",
              "Analyze customer requirements and deploy features based on customer requirements on the CRM.",
              "Deploy CRM on Customer's system (Banking System)"
            ],
            "technologies": [
              "PHP: Vtiger CRM",
              "Database: Mysql"
            ]
          }
        ],
      },
      {
        title: "WEB DEVELOPER",
        period: "10/2017 - 01/2019",
        company: "YeuTheThao",
        teamSize: 3,
        projects: [
          {
            "show": true,
            "title" :"bongda.com.vn",
            "url"   : "https://bongda.com.vn",
            "description": [
              "Football Sports News Site"
            ],
            "responsiblities": [
              "Develop new features and handle errors on the website",
              "Analyze, design the database and propose solutions to the team leader",
              "Optimize page speed and slow queries"
            ],
            "technologies": [
              "Backend : PHP ( MVC manual build )",
              "Frontend: HTML + CSS",
              "Cache   : Memcached",
              "Database: Mysql"
            ],
          },
          {
            "show": true,
            "title" :"tinthethao.com.vn",
            "url"   : "https://tinthethao.com.vn",
            "description": [
              "A Comprehensive Sports News Site"
            ],
            "responsiblities": [
              "Develop new features and handle errors on the website",
              "Analyze, design the database and propose solutions to the team leader",
              "Optimize page speed and slow queries"
            ],
            "technologies": [
              "Backend : PHP ( MVC manual build )",
              "Frontend: HTML + CSS",
              "Cache   : Memcached",
              "Database: Mysql"
            ],
          }
        ],
      }
    ],
    academic: [
      {
        career: "Technical Computer",
        date: "05/2008 - 04/2011",
        institution: "NGUYEN TAT THANH University",
      },
    ],
  }
};
