import React from "react";
import { Description } from "./Description";

const ShowLine = () => <hr />
export const Works = ({ works }) => {
  return (
    <section className="work-experience section" id="experience">
      <h2 className="section-title">Experiences</h2>
      <div className="experience__container bd-grid">
        {works.map((work) => (
          <Work key={work.company} {...work} />
        ))}
      </div>
    </section>
  );
};

const Work = ({ title, period, company, teamSize, projects, tasks, technologies, description, responsiblities }) => {
  return (
    <div className="experience__content">
      <div className="experience__time">
        <span className="experience__rounder"></span>
        <span className="experience__line"></span>
      </div>
      <div className="experience__data bd-grid">
        <h3 className="experience__title label-left">{title}</h3>
        <span className="experience__company">
          {period} | <strong>{company}</strong> 
        </span>
        
        { teamSize && <span className="experience__company"><strong>Team Size : </strong> {teamSize} </span> }

        {/* Show Projects */}
        { 
          projects && projects.length > 0 && <span className="experience__company"><strong>Projects : </strong> 
            <ul className="skills__data ml-15px">
              {projects.map((project, key) => {
                const {title, url, description, tasks, technologies, responsiblities, teamSize } = project
                return (
                  <>
                    <Project key={key} i={key+1} title={title} url={url} description={description}/>
                    {
                      description && description?.length && (
                        <>
                          {/* Show Team Size */}
                          { teamSize && <p><span className="experience__company"><strong>Team Size : </strong> {teamSize} </span></p>}
                          <strong>Project Description</strong>
                          <ul className="skills__data ml-15px">
                            {description && description.map((item, key) => (
                                <li key={key} className="skills__name">
                                  <span className="skills__dash" /> {item}
                                </li>
                              ) )}
                            </ul>
                        </>
                      )
                    }
                    
                    {
                    responsiblities && responsiblities.length && (
                      <>
                        <strong>Responsiblities</strong>
                        <ul className="skills__data ml-15px">
                          {responsiblities && responsiblities.map((item, key) => (
                              <li key={key} className="skills__name">
                                <span className="skills__dash" /> {item}
                              </li>
                            ) )}
                          </ul>
                      </>
                    )
                    }
                    {
                      technologies && technologies.length && (
                        <>
                          <strong>Technologies</strong>
                          <ul className="skills__data ml-15px">
                            {technologies && technologies.map((item, key) => (
                                <li key={key} className="skills__name">
                                  <span className="skills__dash" /> {item}
                                </li>
                              ) )}
                            </ul>
                        </>
                      )
                    }
                    {
                      projects.length > 1 && key !== (projects.length - 1) && <ShowLine />
                    }
                  </>
                )
              } )}
            </ul>
          </span> 
        }
        
        {/* Show Tasks */}
        { 
          tasks && tasks.length > 0 && 
          <span className="experience__company"><strong>Tasks : </strong> 
            <RenderChildren data={tasks}></RenderChildren>
          </span> 
        }

        {/* Show Description */}
        { description && description.length > 0 &&
          <span className="experience__company"><strong>Description : </strong> 
            <RenderChildren data={description}></RenderChildren>
          </span>
        }
         {
          responsiblities && responsiblities?.length > 0 && (
            <>
              <strong>Responsiblities</strong>
              <ul className="skills__data ml-15px">
                {responsiblities && responsiblities.map((item, key) => (
                    <li key={key} className="skills__name">
                      <span className="skills__dash" /> {item}
                    </li>
                  ) )}
                </ul>
            </>
          )
        }
        {/* Show Technologies */}
        { 
          technologies && technologies.length > 0 && 
          <span className="experience__company"><strong>Technologies : </strong> 
            <RenderChildren data={technologies}></RenderChildren>
          </span> 
        }
      </div>
    </div>
  );
};

const Project = ({ i, title, url}) => (
  <li className="skills__name">
    {/* <span className="skills__circle" /> */}
    <span className="num">{i}</span>
      <a className="link" href={url} target="_blank" title={title}>
        <strong style={{fontSize:"16px"}}>{title}</strong>
        {url &&  <i className={`bx bx-link-alt`}></i> }
      </a>
  </li>
);

const RenderChildren = props => {
  return (
    <ul className="skills__data ml-15px">
      {props?.data && props.data.map((item, key) => (
        <li key={key} className="skills__name">
          <span className="skills__circle" /> {item}
        </li>
      ) )}
    </ul>
  )
}