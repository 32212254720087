import React from "react";

export const AboutMe = ({ label, description }) => (
  <section className="profile section" id="profile">
    <h2 className="section-title label-left">{label}</h2>
   {
     description.map(i => <p className="profile__description">{i}</p>)
   }
  </section>
);
