import React from "react";
import { Academic } from "./Academic";
import { Data as dataSchema } from '../Schemas/Data';
const { profile, aboutMe, skills, socialMedia, experience } = dataSchema ;
export const Skills = ({
  technicalLabel,
  softLabel,
  technicalSkills,
  softSkills,
  programmingLaguages,
  versionControl,
  databases,
  
}) => {
  return (
    <>
      <section className="technical-skills section" id="skills">
        <h2 className="section-title label-left">{technicalLabel}</h2>
        <h4 className="experience__title mb-5px">PROGRAMMING LANGUAGES</h4>
        <div className="skills__content bd-grid">
          <ul className="skills__data">
            {programmingLaguages.map((skill) => <Skill key={skill} skill={skill} />)}
          </ul>
        </div>
        <h4 className="experience__title mb-5px">FRAMEWORKS & PLATFORMS</h4>
        <div className="skills__content bd-grid">
          <ul className="skills__data">
            {technicalSkills.map((skill) => <Skill key={skill} skill={skill} />)}
          </ul>
        </div>
        <h4 className="experience__title mb-5px">DATABASE</h4>
        <ul className="skills__data">
          {databases.map((skill) => <Skill key={skill} skill={skill} />)}
        </ul>

        <h4 className="experience__title mb-5px">VERSION CONTROL</h4>
        <ul className="skills__data">
          {versionControl.map((skill) => <Skill key={skill} skill={skill} />)}
        </ul>
      </section>
      {/* <h2 className="section-title label-left">KNOWLEDGE</h2>
      <ul className="skills__data">
        {versionControl.map((skill) => <Skill key={skill} skill={skill} />)}
      </ul> */}
      <Academic {...experience} />
    </>
  );
};

const Skill = ({ skill }) => (
  <li className="skills__name">
    <span className="skills__circle" /> {skill}
  </li>
);
